import React from 'react';
import cn from 'classnames';

import styles from './Copyright.module.scss';

const Copyright = ({ className = '' }) => (
  <div className={cn(styles.wrapper, className)}>
    <p className={styles.text}>Mazaya Business Avenue AA1, Jumeirah Lakes Towers, Dubai</p>
  </div>
);

export default Copyright;
